import axios from '@/http/index'
import {preParams, Extra, BasicTableResult} from "../index";
// import {} from './model/individualModel';

export default {
  /**
   * @description: 勾销校验
   * @return {void}
   */
  'POST/verification/liquidate/validate': (params: any, extra?: Extra) => {
    return axios(`/verification/liquidate/validate`, preParams(params, extra, 'post'))
  },
}
import * as commonLib from "rap/runtime/commonLib";
import { IExtra, AxiosRequest } from '../types'
import axios from "../index";

import achievementsApi from '../api/achievements'
import attendanceApi from '../api/attendance'
import authorizationApi from '../api/authorization'
import bankAccountApi from '../api/bankAccount'
import bankCardApi  from '../api/bankCard'
import bankStatementApi  from '../api/bankStatement'
import billApi  from '../api/bill'
import confirmationApi  from '../api/confirmation'
import costProfitApi  from '../api/costProfit'
import crmApi  from '../api/crm'
import dataChartApi  from '../api/dataChart'
import financialApi  from '../api/financial'
import hrApi  from '../api/hr'
import invoiceApprovalApi  from '../api/invoiceApproval'
import laborContractApi  from '../api/laborContract'
import leaseApi  from '../api/lease'
import meApi  from '../api/me'
import newVerificationApi  from '../api/newVerification'
import onlineDiskApi  from '../api/onlineDisk'
import orderApi  from '../api/order'
import payPoolApi  from '../api/payPool'
import projectApi  from '../api/project'
import reimbursementApi  from '../api/reimbursement'
import salaryApi  from '../api/salary'
import salesmanApi  from '../api/salesman'
import socialApi  from '../api/social'
import structuralApi  from '../api/structural'
import subjectApi  from '../api/subject'
// import sysMessageApi  from '../api/sysMessage'
// import systemApi  from '../api/system'
import transactionRecordApi  from '../api/transactionRecord'
import translateIncomeApi  from '../api/translateIncome'
import CommissionApi from '../api/commission'
import ticketsApi  from '../api/tickets'

import systemApi2 from '../api/system/system'
import authorizationApi2 from '../api/system/authorization'
import hrApi2 from '../api/hr/hr'
import laborApi2 from '../api/hr/labor'
import reportApi2 from '../api/report/report'
import individualApi2 from '../api/individual/individual'
import reimbursementApi2 from '../api/individual/reimbursement'
import verificationApi2 from '../api/finance/verification'
import bankStatementApi2 from '../api/finance/bankStatement'
import incomeCosttApi2 from '../api/finance/incomeCost'
import subjectApi2 from '../api/finance/subject'
import backgroundTasksApi2 from "../api/background_tasks/tasks"
import costMatchApi2 from "../api/cost/match"
import commissionApi2 from "../api/salary/commission"
import salaryApi2 from "../api/salary/salary"
import attendancesnApi2 from "../api/attendances/attendances"
import orderInvoiceApi2 from "../api/order/invoice"
import officeAssetApi from "../api/office_asset/officeAsset"
import payPoolApi2 from "../api/pay_pool/pay_pool"
import maintenancesApi2 from '../api/system/maintenance'
import paymentApi2 from '../api/finance/payment'
import employeeCareApi2 from '../api/employee_care/employee_care'
import invoiceApproveApi2 from '../api/invoice/approve'
import paymentVoucherApi2 from '../api/order/paymentVoucher'
import badDebtApi2 from '../api/finance/badDebt'
import balanceApi2 from '../api/cost/balance'
import cashFlowsApi2 from '../api/cost/cashflows'
import projectApi2 from '../api/project/project'
import orderApi2 from '../api/order/order'
import procuresApi2 from '../api/order/purchase'

// 预处理
const preRequest = async ({ url, method, params, extra = {} }: AxiosRequest ) => {
  if(extra && extra.contentType === "formData"){
    extra.headers = { "Content-Type": "multipart/form-data" }
    const formData = new FormData()

    Object.keys(params).map(_key => {
      formData.append(_key, params[_key])
    })
    params = formData
  }
  // 此处处理formData请求参数，
  try {
    const response: any = await axios({
      url,
      method,
      params: method === "GET" ? params : {},
      data: params,
      ...extra,
    })

    if (response !== undefined && response !== null) { return response }
    return Promise.reject(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

const Api = Object.assign(
  achievementsApi(preRequest),
  attendanceApi(preRequest),
  authorizationApi(preRequest),
  bankAccountApi(preRequest),
  bankCardApi(preRequest),
  bankStatementApi(preRequest),
  billApi(preRequest),
  confirmationApi(preRequest),
  costProfitApi(preRequest),
  crmApi(preRequest),
  dataChartApi(preRequest),
  financialApi(preRequest),
  hrApi(preRequest),
  invoiceApprovalApi(preRequest),
  laborContractApi(preRequest),
  leaseApi(preRequest),
  meApi(preRequest),
  newVerificationApi(preRequest),
  onlineDiskApi(preRequest),
  orderApi(preRequest),
  payPoolApi(preRequest),
  projectApi(preRequest),
  reimbursementApi(preRequest),
  salaryApi(preRequest),
  salesmanApi(preRequest),
  socialApi(preRequest),
  structuralApi(preRequest),
  subjectApi(preRequest),
  // sysMessageApi(preRequest),
  // systemApi(preRequest),
  transactionRecordApi(preRequest),
  translateIncomeApi(preRequest),
  CommissionApi(preRequest),
  ticketsApi(preRequest),
  systemApi2,
  authorizationApi2,
  hrApi2,
  laborApi2,
  reportApi2,
  individualApi2,
  reimbursementApi2,
  verificationApi2,
  bankStatementApi2,
  incomeCosttApi2,
  subjectApi2,
  backgroundTasksApi2,
  commissionApi2,
  salaryApi2,
  costMatchApi2,
  attendancesnApi2,
  orderInvoiceApi2,
  payPoolApi2,
  maintenancesApi2,
  employeeCareApi2,
  officeAssetApi(preRequest),
  paymentApi2,
  invoiceApproveApi2,
  paymentVoucherApi2,
  badDebtApi2,
  balanceApi2,
  cashFlowsApi2,
  projectApi2,
  orderApi2,
  procuresApi2
)

// const Api = createFetch(preRequest, { fetchType: commonLib.FetchType.BASE })
export default Api
import axios from '@/http/index'
import {preParams, Extra, BasicTableResult} from "../index";
// import {} from './model/individualModel';

export default {
  /**
   * @description: 国内外收入
   * @return {void}
   */
  'POST/order_profit/domestic_income': (params: any, extra?: Extra) => {
    return axios(`/order_profit/domestic_income`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 流水提现包明细
   * @return {void}
   */
  'POST/flow_pack_details/query': (params: any, extra?: Extra) => {
    return axios(`/flow_pack_details/query`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 生成凭证记录
   * @return {void}
   */
  'POST/order_profit/generate_voucher': (params: any, extra?: Extra) => {
    return axios(`/order_profit/generate_voucher`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 销售订单分析列表
   * @return {void}
   */
  'POST/order_profit/saleOrder/list': (params: any, extra?: Extra) => {
    return axios(`/order_profit/saleOrder/list`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 获取订单销售分析客户名称
   * @return {void}
   */
  'GET/customer_basic_info': (params: any, extra?: Extra) => {
    return axios.get<any[]>(`/customer_basic_info`)
  },

  /**
   * @description: 获取订单销售分析团队名称
   * @return {void}
   */
  'GET/organizations/coTwoOrgId': (params: any, extra?: Extra) => {
    return axios.get<any[]>(`/organizations/coTwoOrgId`)
  },

}
import axios from '@/http/index'
import {preParams, Extra, BasicTableResult} from "../index";

export default {
  /**
   * @description: 采购付款页面
   */
  'POST/procures/payment': (params: any, extra?: Extra) => {
    return axios(`/procures/payment`, preParams(params, extra, 'post'))
  },
  /**
   * @description: 导出-采购付款
   */
  'POST/procures/export': (params: any, extra?: Extra) => {
    return axios(`/procures/export`, preParams(params, extra, 'post'))
  },
  /**
   * @description: 采购批次列表(不分页)
   */
  'GET/procures': (params: any, extra?: Extra) => {
    return axios(`/procures`, preParams(params, extra, 'get'))
  },
  /**
   * @description: 批量多选加入采购批次
   */
  'POST/procures/choose_add': (params: any, extra?: Extra) => {
    return axios(`/procures/choose_add`, preParams(params, extra, 'post'))
  },
  /**
   * @description: 批量选择删除加入批次
   */
  'DELETE/procures/choose_deleted': (params: any, extra?: Extra) => {
    return axios(`/procures/choose_deleted`, preParams(params, extra, 'delete'))
  },
  /**
   * @description: 根据条件删除采购批次
   */
  'DELETE/procures/condition_deleted': (params: any, extra?: Extra) => {
    return axios(`/procures/condition_deleted`, preParams(params, extra, 'delete'))
  },
  /**
   * @description: 根据条件加入采购批次
   */
  'POST/procures/condition_add': (params: any, extra?: Extra) => {
    return axios(`/procures/condition_add`, preParams(params, extra, 'post'))
  },
  /**
   * @description: 采购付款汇总
   */
  'POST/procures/page': (params: any, extra?: Extra) => {
    return axios(`/procures/page`, preParams(params, extra, 'post'))
  },
  /**
   * @description: 新增-采购单
   */
  'POST/procures': (params: any, extra?: Extra) => {
    return axios(`/procures`, preParams(params, extra, 'post'))
  },
  /**
   * @description: 修改-采购单
   */
  'PATCH/procures': (params: any, extra?: Extra) => {
    return axios(`/procures`, preParams(params, extra, 'patch'))
  },
  /**
   * @description: 上传-支付文件(可同时上传多个)
   */
  'POST/procures/upload': (params: any, extra?: Extra) => {
    return axios(`/procures/upload`, preParams(params, extra, 'post'))
  },
}
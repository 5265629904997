import axios from '@/http/index'
import {preParams, Extra, BasicTableResult} from "../index";
// import {} from './model/individualModel';

export default {
  /**
   * @description: 创建节假日方案
   * @param {string} startDate 开始日期
   * @param {string} endDate 结束日期
   * @param {string} description 备注
   * @param {array} holidaySchemes 
   * @return {array}
   */
  'POST/checkin_holidays': (params: any, extra: Extra) => {
    return axios(`/checkin_holidays`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 修改节假日方案
   * @param {string} startDate 开始日期
   * @param {string} endDate 结束日期
   * @param {string} description 备注
   * @param {array} holidaySchemes 
   * @return {array}
   */
  'PATCH/checkin_holidays': (params: any, extra: Extra) => {
    return axios(`/checkin_holidays`, preParams(params, extra, 'patch'))
  },

  /**
   * @description: 查询节假日方案
   * @param {number} page
   * @param {number} size
   * @return {array}
   */
  'POST/checkin_holidays/list': (params: {page: number, size: number}, extra: Extra) => {
    return axios(`/checkin_holidays/list`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 获取可加班换薪记录
   * @return {array}
   */
  'GET/checkin_balances/overtime_salary': () => axios.get<any[]>(`/checkin_balances/overtime_salary`),

  /**
   * @description: 加班换薪记录附件预览
   * @return {array}
   */
  'GET/checkin_balances/view_overtime_file': (params: any, extra: Extra) => {
    return axios.get<any>(`/checkin_balances/view_overtime_file`, preParams(params, extra, 'get'))
  },
  /**
   * @description: 假期余额-获取单个详情
   * @return {array}
   */
  'GET/checkin_approvals/id': (params: any, extra: Extra) => {
    return axios.get<any>(`/checkin_approvals/${params.id}`, preParams({}, extra, 'get'))
  },


}
import { ref } from 'vue'
import { createStore } from 'vuex'
import getters from "./getters";
import permissionMd from './modules/permission'
import messageMd from './modules/message'
import router from "../router/index";
import { ElMessage } from 'element-plus'

interface globalStore {
  path?: string | object
  userId: string
  token: string
  initialLogin: boolean
  changePas: boolean
  loginAga: boolean
  userName?: string,
  payData?:Array<any>,
  Payment?:Array<any>,
  approveNumber: number,
  sysUsers?: any
  sysCompany?: Array<any>
  sysOrganizations?: Array<any>
}

// default system variable
let globalStoreState: globalStore = {
  path: '',
  userName: localStorage.getItem('USER_NAME') || '',
  userId: localStorage.getItem('USER_ID') || '',
  token: localStorage.getItem('TOKEN') || '',
  // ture：初次登录
  initialLogin: Boolean(localStorage.getItem('INITIALOGIN')) || false,
  changePas: false,
  loginAga: false,
  payData:[],
  Payment:[],
  approveNumber: 0,
  // 用户下拉
  sysUsers: [],
  sysCompany: [],
  sysOrganizations: [],
};

export default createStore({
  state: globalStoreState,
  mutations: {
    SET_CURRENT_ROUTE(state, path){
      state.path = path
    },
    SET_USER_NAME(state, userName) {
      state.userName = userName
      localStorage.setItem('USER_NAME', userName)
    },
    SET_USER_ID(state, userId){
      state.userId = userId
      localStorage.setItem('USER_ID', userId)
    },
    SET_TOKEN(state, token: string){
      state.token = token
      localStorage.setItem('TOKEN', token)
    },

    SET_INITIA_LOGIN(state, flag: boolean){
      state.initialLogin = flag
      if(flag){
        state.changePas = true
      } else {
        state.changePas = false
      }
      localStorage.setItem('INITIALOGIN', flag === true ? 'true' : '')
    },
    SET_CAHNGE_PAS(state, flag: boolean){
      state.changePas = flag
    },
    SET_LOGIN_AGAIN(state, flag: boolean){
      // 二次登录
      state.loginAga = flag
    },
    SET_PAY_DATA(state,data:Array<any>){
      state.payData = data
    },
    SET_PAYMENT_DATA(state,data:Array<any>){
      state.Payment = data
    },
    SET_APPROVE_NUMBER(state, number: number){
      state.approveNumber = number
    },
    SET_SYS_USERS(state, arr: any[]){
      state.sysUsers = arr
    },
    SET_SYS_COMPANY(state, arr: any[]){
      // state.sysCompany = arr
      state.sysCompany?.splice(0)
      state.sysCompany?.push(...arr)
    },
    SET_SYS_ORGANIZATIONS(state, arr: any[]){
      // state.sysOrganizations = arr
      state.sysOrganizations?.splice(0)
      state.sysOrganizations?.push(...arr)
    }
  },
  actions: {
    logout(context, routePath){
      context.commit('SET_TOKEN', '')
      context.commit('permissionMd/SET_PRIVILEGES', false)
      router.push({path: '/login', query: {redirect: routePath.path, ...routePath.query}})
      // router.push({path: '/login', query: {...(context.state.path?.query || {}), redirect: context.state.path?.path || '/'}})
      ElMessage('请登录')
    }
  },
  getters,
  modules: {
    permissionMd,
    messageMd
  }
})

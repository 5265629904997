import axios from '@/http/index'
import {preParams, Extra, BasicTableResult} from "../index";
import {
  ResourcesItem,
  RoleType,
} from './model/authorizationModel';


export default {
  /**
   * @description: 获取账号已有权限
   * @return {void}
   */
  'GET/permission': (params: any, extra?: Extra) => axios.get<object>(`/permission`),
  
  /**
   * @description: 用户管理列表
   * @param {string} query 姓名
   * @return {void}
   */
  'GET/users': (params: {query: string}, extra?: Extra) => {
    return axios.get<object[]>(`/users`, preParams(params, extra, 'get'))
  },
  
  /**
   * @description: 获取资源可选权限
   * @return {void}
   */
  'GET/resources/actions': (params?: any, extra?: Extra) => axios.get<object[]>(`/resources/actions`),

  /**
   * @description: 创建资源
   * @param {string} id 资源ID
   * @param {string} name 资源名
   * @param {string} description 描述
   * @param {array} actions 权限
   * @return {void}
   */
  'POST/resources': (params: ResourcesItem, extra?: Extra) => {
    return axios(`/resources`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 编辑资源
   * @param {string} id 资源ID
   * @param {string} name 资源名
   * @param {string} description 描述
   * @param {array} actions 权限
   * @return {void}
   */
  'PUT/resources': (params: ResourcesItem, extra?: Extra) => {
    return axios(`/resources`, preParams(params, extra, 'put'))
  },

  /**
   * @description: 资源列表
   * @param {string} name 资源名
   * @param {number} page
   * @param {number} size
   * @return {void}
   */
  'GET/resources': (params: {name: string}, extra?: Extra) => {
    return axios.get<any>(`/resources`, preParams(params, extra, 'get'))
  },

  /**
   * @description: 获取资源
   * @param {string} name 资源名
   * @return {void}
   */
  'GET/resources/{id}': (params: {id: string}, extra?: Extra) => {
    return axios.get<any>(`/resources/${params.id}`, preParams(params, extra, 'get'))
  },

  /**
   * @description: 删除资源
   * @param {number} id 资源ID
   * @return {void}
   */
  'DELETE/resources/{id}': (params: {id: string}, extra?: Extra) => {
    return axios.delete<any>(`/resources/${params.id}`)
  },

  /**
   * @description: 获取某个资源，被多少人使用
   * @param {number} id 资源ID
   * @return {void}
   */
  'GET/resources/{id}/use': (params: {id: string}, extra?: Extra) => {
    return axios.get<any>(`/resources/${params.id}/use`)
  },

  /**
   * @description: 获取某个资源，被多少人使用
   * @param {string} ids 资源ids
   * @return {void}
   */
  'POST/resources/use/export': (params: {ids: string}, extra?: Extra) => {
    return axios(`/resources/use/export`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 角色列表
   * @return {void}
   */
  'GET/roles': (params: {id: string}, extra?: Extra) => {
    return axios.get<any>(`/roles`)
  },

  /**
   * @description: 创建角色
   * @param {string} id 角色ID
   * @param {string} name 角色名称
   * @param {string} description 角色描述(备注)
   * @param {array} privileges 角色资源
   * @return {void}
   */
  'POST/roles': (params: RoleType, extra?: Extra) => {
    return axios(`/roles`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 更新角色
   * @param {string} id 角色ID
   * @param {string} name 角色名称
   * @param {string} description 角色描述(备注)
   * @param {array} privileges 角色资源
   * @return {void}
   */
  'PUT/roles': (params: RoleType, extra?: Extra) => {
    return axios(`/roles`, preParams(params, extra, 'put'))
  },

  /**
   * @description: 更新角色
   * @param {string} id 角色ID
   * @return {void}
   */
  'DELETE/roles/{id}': (params: {id: number}, extra?: Extra) => {
    return axios.delete<any>(`/roles/${params.id}`)
  },

  /**
   * @description: 创建员工角色
   * @param {number} userId 用户ID
   * @param {array} role 角色list
   * @param {array} privileges 额外权限
   * @param {string} description 角色描述(备注)
   * @return {void}
   */
  'POST/users/privileges': (params: any, extra?: Extra) => {
    return axios(`/users/privileges`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 获取员工已有角色
   * @param {number} userId 用户ID
   * @return {void}
   */
  'GET/users/privileges/{userId}': (params: {userId: number}, extra?: Extra) => {
    return axios.get<void>(`/users/privileges/${params.userId}`)
  },

  /**
   * @description: 其他平台账号列表
   * @param {string} key 姓名
   * @param {array} orgIds 团队IDs
   * @param {array} staffType 人员状态list
   * @return {void}
   */
  'POST/sso/account/list': (params: any, extra?: Extra) => {
    return axios(`/sso/account/list`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 其他平台账号权限详情
   * @param {string} email 邮箱
   * @return {void}
   */
  'POST/sso/account/permissions': (params: {email: string}, extra?: Extra) => {
    return axios(`/sso/account/permissions`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 修改用户其他平台权限
   * @param {array} ssoPlatformAccountManageList 权限list
   * @return {void}
   */
  'POST/sso/update_platform_account_permissions': (params: any, extra?: Extra) => {
    return axios(`/sso/update_platform_account_permissions`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 创建或修改其他平台
   * @param {number} id 平台ID(编辑需要平台ID)
   * @param {string} platform 平台名称
   * @param {string} desc 平台描述（备注）
   * @return {void}
   */
  'POST/sso/platform': (params: any, extra?: Extra) => {
    return axios(`/sso/platform`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 获取所有其他平台
   * @return {void}
   */
  'GET/sso/platforms': (params: any, extra?: Extra) => {
    return axios.get<any[]>(`/sso/platforms`)
  },

  /**
   * @description: 获取所有其他平台
   * @return {void}
   */
  'POST/sso/batch_import_platform_permissions': (params: any, extra?: Extra) => {
    return axios(`/sso/batch_import_platform_permissions`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 资源权限 删除用户
   * @return {void}
   */
  'DELETE/resources_user/user': (params: any, extra?: Extra) => {
    return axios.delete<void>(`/resources_user/user?user_id=${params.userId}&resource_id=${params.resourceId}`,)
  },
}
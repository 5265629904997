import axios from '@/http/index'
import {preParams, Extra, BasicTableResult} from "../index";
// import {} from './model/individualModel';

export default {
  /**
 * @description: 代付凭证-代付凭证_查询
 * @return {void}
 */
  'POST/order/payment_voucher/query': (params: any, extra?: Extra) => {
    return axios(`/order/payment_voucher/query`, preParams(params, extra, 'post'))
  },
  /**
 * @description: 代付凭证_代付预警_查询
 * @return {void}
 */
  'POST/order/payment_voucher/alert/query': (params: any, extra?: Extra) => {
    return axios(`/order/payment_voucher/alert/query`, preParams(params, extra, 'post'))
  },
  /**
 * @description: 代付凭证_加入黑/白名单
 * @return {void}
 */
  'POST/order/payment_voucher/roster/save': (params: any, extra?: Extra) => {
    return axios(`/order/payment_voucher/roster/save`, preParams(params, extra, 'post'))
  },
  /**
 * @description: 代付凭证_名单管理_查询
 * @return {void}
 */
  'POST/order/payment_voucher/roster/query': (params: any, extra?: Extra) => {
    return axios(`/order/payment_voucher/roster/query`, preParams(params, extra, 'post'))
  },
  /**
 * @description: 代付凭证_预览
 * @return {void}
 */
  'GET/upload/download_file': (params: any, extra?: Extra) => {
    return axios(`/upload/download_file`, preParams(params, extra, 'get'))
  },
  /**
 * @description: 代付凭证_上传支付凭证
 * @return {void}
 */
  'POST/order/payment_voucher/upload': (params: any, extra?: Extra) => {
    return axios(`/order/payment_voucher/upload`, preParams(params, extra, 'post'))
  },
  /**
 * @description: 社保缴费明细-上传记录，生成账单
 * @return {void}
 */
  'POST/social_security_bureau_datas/merge_voucher': (params: any, extra?: Extra) => {
    return axios(`/social_security_bureau_datas/merge_voucher`, preParams(params, extra, 'post'))
  },
  /**
 * @description: 订单收款_查询收款记录的凭证文件
 * @return {void}
 */
  'GET/order/receipt/payment_record/payment_voucher/:id': (params: any, extra?: Extra) => {
    return axios(`/order/receipt/payment_record/payment_voucher/${params.id}`, preParams({}, extra, 'get'))
  },
  /**
 * @description: 代付凭证_名单管理_删除
 * @return {void}
 */
  'DELETE/order/payment_voucher/roster/{payerId}': (params: any, extra?: Extra) => {
    return axios(`/order/payment_voucher/roster/${params.payerId}`, preParams({}, extra, 'delete'))
  },
  /**
 * @description: 代付凭证_名单管理_删除
 * @return {void}
 */
  'DELETE/order/payment_voucher/file/delete': (params: any, extra?: Extra) => {
    return axios(`/order/payment_voucher/file/delete?fileId=${params.fileId}&id=${params.id}`, preParams({}, extra, 'delete'))
  },
}